import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var homeRoute = [{
  path: "/home",
  name: "home",
  // component: () => import("../../views-v2/login/index.vue")
  component: function component() {
    return import("./home.vue");
  }
}];