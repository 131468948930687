
        var result = require("!!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-2-2!./spinner.overlay.component.css?string");

        if (result && result.__esModule) {
            result = result.default;
        }

        if (typeof result === "string") {
            module.exports = result;
        } else {
            module.exports = result.toString();
        }
    