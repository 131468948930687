import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { loginRoute } from "./login/login.route.js";
import { registerRoute } from "./register/register.route.js";
import { forgetPasswordRoute } from "./forget-password/forget-password.route.js";
export var accountRoute = [{
  path: "/account",
  name: "account",
  component: function component() {
    return import("./account.vue");
  },
  children: loginRoute.concat(registerRoute).concat(forgetPasswordRoute)
}];