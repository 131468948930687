import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { listRoute } from "./product-list/list.route.js";
import { detailsRoute } from "./product-details/details.route.js";
export var productRoute = [{
  path: "/product",
  name: "product",
  component: function component() {
    return import("./product.vue");
  },
  children: listRoute.concat(detailsRoute)
}];