import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import VueRouter from "vue-router";
var push = VueRouter.prototype.push;
var replace = VueRouter.prototype.replace;
var vueRouter = {
  push: {
    repaired: false,
    repair: function repair() {
      if (!this.repaired) {
        this.repaired = true;
        VueRouter.prototype.push = function (location, onComplete, onAbort) {
          var _this = this;
          return push.apply(this, [location, onComplete, onAbort]).catch(function (error) {
            if (error.type === 4) {
              if ({}.toString.call(location) === "[object String]") {
                location = {
                  path: location
                };
              }
              if ({}.toString.call(location) === "[object Object]") {
                location.query || (location.query = {});
                location.query._refresh = Math.random() * 1000000000000000000;
                replace.apply(_this, [location, onComplete, onAbort]);
              }
            }
            return error;
          });
        };
      }
    }
  },
  replace: {
    repaired: false,
    repair: function repair() {
      if (!this.repaired) {
        this.repaired = true;
        VueRouter.prototype.replace = function (location, onComplete, onAbort) {
          var _this2 = this;
          return replace.apply(this, [location, onComplete, onAbort]).catch(function (error) {
            if (error.type === 4) {
              if ({}.toString.call(location) === "[object String]") {
                location = {
                  path: location
                };
              }
              if ({}.toString.call(location) === "[object Object]") {
                location.query || (location.query = {});
                location.query._refresh = Math.random() * 1000000000000000000;
                replace.apply(_this2, [location, onComplete, onAbort]);
              }
            }
            return error;
          });
        };
      }
    }
  }
};
export default vueRouter;