import Vue from 'vue';
import Message from './index.vue';
var messageBox = Vue.extend(Message);
var install = function install(options, type) {
  if (options === undefined || options === null) {
    options = {
      content: ''
    };
  } else if (typeof options === 'string' || typeof options === 'number') {
    options = {
      content: options
    };
    if (type != undefined && options != null) {
      options.type = type;
    }
  }
  var instance = new messageBox({
    data: options
  }).$mount();
  document.body.appendChild(instance.$el);
  Vue.nextTick(function () {
    instance.visible = true;
  });
};
export default install;