import "/var/jenkins_home/workspace/frontend-web-shop/node_modules/core-js/modules/es.array.iterator.js";
import "/var/jenkins_home/workspace/frontend-web-shop/node_modules/core-js/modules/es.promise.js";
import "/var/jenkins_home/workspace/frontend-web-shop/node_modules/core-js/modules/es.object.assign.js";
import "/var/jenkins_home/workspace/frontend-web-shop/node_modules/core-js/modules/es.promise.finally.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
// 引入 polyfill
import "./polyfill.js";

// 引入 VUE 和其关联库
import Vue from "vue";
import infiniteScroll from "vue-infinite-scroll";
import Buefy from "buefy";
import "buefy/dist/buefy.css";

// 引入 fontawesome 库
import "@fortawesome/fontawesome-free/css/all.css";

// 引入本地公共样式
import "@css/base.css";
import "@css/global.less";

//v2
import "@cssv2/global.less";
import '@/assets-v2/css/product-list.less';
//字体库
import "@/assets-v2/fonts/iconfont.css";
import "@/assets-v2/iconfont/iconfont.css";

// 引入本地模块
import { Toast } from "@modules/toast";
import { VueComponent } from "@modules/vue-component";

// 引入本地服务
import { HttpClient } from "@services/http";
import { AccountStorage } from "@services/account";

// 项目初始化
import router from "./router.js";
import Index from "./views/index.vue";

// 引入filters
import * as filters from "./filters/filters";
import stroe from "./store/index";
import Confirm from "@modules/v2-confirm/index.js";
import MessageBox from "@modules/v2-messagebox/index.js";
Vue.prototype.$messageTip = MessageBox;
Vue.prototype.$confirm = Confirm;
Vue.prototype.$store = stroe;
Vue.prototype.supplierToken = {
  headers: {
    authcode: 'asdklaslkd'
  }
};
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.config.productionTip = false;
Vue.use(infiniteScroll);
Vue.use(ElementUI);
Vue.use(Buefy, {
  defaultIconPack: "fas",
  defaultLocale: "zh"
});
Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.prototype.$toast = new Toast();
var accountStorage = new AccountStorage();
Vue.prototype.$getHttpClient = function () {
  var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var requestOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (options.timeout) {
    requestOptions.timeout = options.timeout;
  } else {
    requestOptions.timeout || (requestOptions.timeout = 30000);
  }
  requestOptions.headers || (requestOptions.headers = {});
  requestOptions.headers = Object.assign({
    AccessToken: accountStorage.getCacheAccessToken() || "",
    "X-AURORA-TOKEN": "Vuc2gPv0kxqagTZD"
  }, requestOptions.headers);
  return new HttpClient(options, requestOptions);
};

// 全局变量./views/index.vue
import global from "./views/global.vue";
Vue.prototype.COMMON = global;
//在线日志统计
var online = function online() {
  var url = '/pjyy-deepexi-collect-center/api/v1/analysis/api/log';
  var userInfo = accountStorage.getCacheUserInfo();
  if (userInfo == null) {
    return;
  }
  var param = {
    customerCode: userInfo.account,
    system: '3',
    //pc
    sourceType: 0,
    version: navigator.userAgent
  };
  Vue.prototype.$getHttpClient({
    useSpanner: false
  }).post(url, param);
};
var indexComponent = new VueComponent(Index, {
  router: router
});
router.afterEach(function () {
  window.scrollTo(0, 0);
  online();
});
indexComponent.mount("#app");