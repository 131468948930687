import "core-js/modules/es.array.push.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.promise.all-settled.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/web.dom-collections.iterator.js";
// promise 功能补全
import allSettled from "promise.allsettled";
if (!Promise.allSettled) {
  allSettled.shim();
}

// webcomponents polyfill.
import "@webcomponents/webcomponentsjs";

// CSS 重置
import "normalize.css";

// VueRouter 功能修补
import vueRouter from "./polyfill/vue-router";
if (!vueRouter.push.repaired) {
  vueRouter.push.repair();
}
if (!vueRouter.replace.repaired) {
  vueRouter.replace.repair();
}