import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var dataDownloadRoute = [{
  path: "data-download",
  name: "data-download",
  component: function component() {
    return import( /* webpackChunkName: "login" */"./data-download.vue");
  },
  meta: {
    keepAlive: false
  }
}, {
  path: "drug-download",
  name: "drug-download",
  component: function component() {
    return import( /* webpackChunkName: "login" */"./drug-download.vue");
  },
  meta: {
    keepAlive: true
  }
}, {
  path: "data-download-detail",
  name: "dataDownload-detail",
  component: function component() {
    return import( /* webpackChunkName: "login" */"./data-download-detail.vue");
  }
}];