import { Account } from "@services/account";
var account = new Account();
export var beforeEnter = function beforeEnter(to, from, next) {
  if (account.isLogined()) {
    next();
  } else {
    next({
      name: "v2-login"
    });
  }
};