import "core-js/modules/es.function.name.js";
export default {
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  data: function data() {
    return {
      RouterState: true,
      CurrentMonthStatistics: {}
    };
  },
  computed: {
    isLoging: function isLoging() {
      return this.$route.name === "accountLogin" || this.$route.name === "forget-password" || this.$route.name === "register" || this.$route.name === "notice";
    }
  },
  methods: {
    reload: function reload() {
      var _this = this;
      this.RouterState = false;
      this.$nextTick(function () {
        _this.RouterState = true;
      });
    }
  }
};