import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { messageCenterListRoute } from "./message-center-list/message-center-list.route.js";
import { messageCenterDetailRoute } from "./message-center-detail/message-center-detail.route";
export var messageCenterRoute = [{
  path: "/message-center",
  name: "messageCenter",
  component: function component() {
    return import("./message-center.vue");
  },
  children: messageCenterListRoute.concat(messageCenterDetailRoute)
}];