import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.math.trunc.js";
import "core-js/modules/es.number.to-fixed.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.replace.js";
// 金钱补0
export var NumFormat = function NumFormat(value) {
  if (!value) return "0.00";
  value = value.toFixed(2);
  var intPart = Math.trunc(value); // 获取整数部分
  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
  var floatPart = ".00"; // 预定义小数部分
  var value2Array = value.split(".");
  // =2表示数据有小数位
  if (value2Array.length === 2) {
    floatPart = value2Array[1].toString(); // 拿到小数部分
    if (floatPart.length === 1) {
      // 补0,实际上用不着
      return intPartFormat + "." + floatPart + "0";
    } else {
      return intPartFormat + "." + floatPart;
    }
  } else {
    return intPartFormat + floatPart;
  }
};
// 去掉 时间 00:00:00
export var formatDate = function formatDate(value) {
  if (value) {
    var date = new Date(value);
    var y = date.getFullYear();
    var MM = date.getMonth() + 1;
    var dd = date.getDate();
    MM = MM < 10 ? "0" + MM : MM;
    dd = dd < 10 ? "0" + dd : dd;
    return y + "-" + MM + "-" + dd;
  }
};
// 字数...显示
export var ellipsis = function ellipsis(value) {
  if (!value) return "";
  if (value.length > 15) {
    return value.slice(0, 15) + "...";
  }
  return value;
};
// TOD
export var manage = function manage(value) {
  if (value === "null-") {
    return " ";
  }
  return value;
};
// 字数...显示
export var ellipsiss = function ellipsiss(value) {
  if (!value) return "";
  if (value.length > 13) {
    return value.slice(0, 13) + "...";
  }
  return value;
};
// 字数...显示
export var ellipsisss = function ellipsisss(value) {
  if (!value) return "";
  if (value.length > 20) {
    return value.slice(0, 20) + "...";
  }
  return value;
};

/**
 * 过滤特殊字符
 */
export var specialCharacters = function specialCharacters(string) {
  return string.replace(/[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g, "");
};
export var formatDate1 = function formatDate1(value) {
  var date = new Date(value);
  var y = date.getFullYear();
  var MM = date.getMonth() + 1;
  MM = MM < 10 ? "0" + MM : MM;
  var d = date.getDate() + 1;
  d = d < 10 ? "0" + d : d;
  var h = date.getHours();
  h = h < 10 ? "0" + h : h;
  var m = date.getMinutes();
  m = m < 10 ? "0" + m : m;
  var s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return y + "-" + MM;
};