import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { beforeEnter } from "@/router.guard.js";
import { addressRoute } from "./address/address.route.js";
import { integralRoute } from "./integral/integral.route";
import { serviceRoute } from "./service/service.route";
import { complainRoute } from "./complain/complain.route";
import { centerRoute } from "./center/center.route";
import { orderRoute } from "./order/order.route";
import { invoiceRoute } from "./invoice/invoice.route";
import { electronicInvoiceRoute } from "./electronicInvoice/electronicInvoice.route";
import { orderDetailRoute } from "./order-detail/order-detail.route";
import { orderDetailRebateRoute } from "./order-detail-rebate/order-detail-rebate.route";
import { afterSaleRoute } from "./after-sale/after-sale.route";
import { applyGoodsRoute } from "./apply-goods/apply-goods.route";
import { userMessageRoute } from "./user-message/user-message.route";
import { afterSaleDetailRoute } from "./after-sale-detail/after-sale-detail.route";
import { enshrineRoute } from "./enshrine/enshrine.route";
import { couponRoute } from "./coupon/coupon.route";
import { salesDetailRoute } from "./sales-detail/sales-detail.route";
import { dataDownloadRoute } from "./data-download/data-download.route";
import { myAccountRoute } from "./my-account/my-account.route";
import { debtMessageRoute } from "./debt-message/debt-message.route";
import { submitQualification } from "./submit-qualification/submit-qualification.route";
import { myAudit } from "./my-audit/my-audit.route";
import { auditDetail } from "./audit-detail/audit-detail.route";
import { myGroupBooking } from "./group-booking/group-booking.route";
import { postalCashHistory } from "./postal-cash-history/postal-cash-history.route";
import { myLicence } from "./my-licence/my-licence.route";
import { billRoute } from "./bill/bill.route";
import { planBookRoute } from "./plan-book/plan-book.route";
export var userCenterRoute = [{
  path: "/user-center",
  name: "userCenter",
  component: function component() {
    return import("./user-center.vue");
  },
  beforeEnter: beforeEnter,
  children: addressRoute.concat(integralRoute).concat(serviceRoute).concat(complainRoute).concat(centerRoute).concat(orderRoute).concat(invoiceRoute).concat(electronicInvoiceRoute).concat(orderDetailRoute).concat(orderDetailRebateRoute).concat(afterSaleRoute).concat(applyGoodsRoute).concat(userMessageRoute).concat(afterSaleDetailRoute).concat(enshrineRoute).concat(couponRoute).concat(salesDetailRoute).concat(dataDownloadRoute).concat(myAccountRoute).concat(debtMessageRoute).concat(submitQualification).concat(myAudit).concat(auditDetail).concat(myGroupBooking).concat(postalCashHistory).concat(myLicence).concat(billRoute).concat(planBookRoute)
}];