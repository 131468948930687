import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var listRoute = [{
  path: "list/:id",
  name: "productList",
  component: function component() {
    return import("./list.vue");
  }
}, {
  path: "activity",
  name: "activityztList",
  component: function component() {
    return import("./activity.vue");
  }
}, {
  path: "makebill",
  name: "makebillList",
  component: function component() {
    return import("./makebill.vue");
  }
}];