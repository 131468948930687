import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { billListRoute } from "./list/list.route";
import { billDetailRoute } from "./detail/detail.route";
export var billRoute = [{
  path: "bill",
  name: "bill",
  component: function component() {
    return import("./bill.vue");
  },
  children: billListRoute.concat(billDetailRoute)
}];