import _typeof from "/var/jenkins_home/workspace/frontend-web-shop/node_modules/@babel/runtime/helpers/esm/typeof.js";
import Vue from 'vue';
import Main from './index.vue';
var ConfirmBox = Vue.extend(Main);

// 全局注册组件
var Confirm = function Confirm(content, title, options) {
  if (_typeof(title) === 'object') {
    options = title;
    title = '';
  } else if (title === undefined) {
    title = '';
  }
  options = Object.assign({
    title: title,
    content: content
  }, options);
  var instance = new ConfirmBox({
    data: options
  }).$mount();
  document.body.appendChild(instance.$el);
  return instance.confirm();
};
export default Confirm;