import qs from "qs";
import axios from "axios";
import { Toast } from "@modules/toast";
import { Spinner } from "@modules/spinner";
import { Message } from "@modules/message";
import { Base } from "@services/base";
import { Navigation } from "@services/navigation";
import { AccountStorage } from "@services/account";
const accountStorage = new AccountStorage();
export class HttpClient {
  #base = new Base();
  #axios = axios;
  #navigation = new Navigation();
  #axiosInstance;
  #interceptors = {
    request: [],
    response: []
  };
  #options = {};
  #requestOptions = {};

  #requestIsTimeout = error => {
    return (
      error.code === "ECONNABORTED" && error.message.indexOf("timeout") !== -1
    );
  };

  #createRequest = ({ method, url, data, config = {} }) => {
    const id = this.#createRequestId();
    const source = this.#createCancelTokenSource();
    let request = 0;
    method = method.toLowerCase();
    config.headers || (config.headers = {});
    config.headers["Request-Id"] = id;
    config.headers["deviceType"]=1; //pc端
    config.headers['system'] = 'PC'
    config.cancelToken = source.token;
    if (method === "request" || method === "getUri") {
      request = this.#axiosInstance[method](config);
    } else if (
      method === "get" ||
      method === "head" ||
      method === "delete" ||
      method === "options"
    ) {
      request = this.#axiosInstance[method](url, config);
    } else if (method === "put" || method === "post" || method === "patch") {
      request = this.#axiosInstance[method](url, data, config);
    }
    const timer = {
      timeout: false,
      timestamp: 0
    };
    if (this.#options.duration > 0) {
      timer.timestamp = setTimeout(() => {
        timer.timeout = true;
        clearTimeout(timer.timestamp);
      }, this.#options.duration);
    }
    HttpClient.requests.set(id, [request, source.cancel, timer]);
    return request;
  };

  #createRequestId = () => {
    return Math.floor(Math.random() * Math.pow(10, 18));
  };

  #responseFatmat = (responseData = {}) => {
    const success = responseData.success;
    if ({}.toString.call(success) === "[object Boolean]") {
      if (success) {
        const content = responseData.content;
        if (content) {
          responseData.msg = "success";
          responseData.code = 200;
          responseData.data = content;
          delete responseData.content;
          delete responseData.success;
          return responseData;
        }
      } else {
        const message = responseData.message;
        if (message) {
          return {
            msg: message,
            code: 0
          };
        }
      }
    }
    return responseData;
  };

  #responseProcessor = response => {
    const data = this.#responseFatmat(response.data);
    const code = +data.code;
    if (code === 401 || code === 403) {
      if (this.#options.useResponseRedirectLogin) {
        // this.cancel();
        this.#navigation.toLogin();
      }
    } else if (code === 302){
        accountStorage.removeCacheUserInfo();
        accountStorage.removeCacheUserList();
        setTimeout(()=>{
            window.location.href = data.msg;
        },50)
    } else if (code === 703) {
      this.#navigation.toNotice();
    } else if(code == 429){
        const ENV_APP = process.env.VUE_APP_API_SERVER;
        if (ENV_APP == "https://api.pujian.com") {
            window.location.href = "https://pay.pujian.com/verify.html?isApp=0&env=prd&token=" + accountStorage.getCacheAccessToken() || ""
        } else if (ENV_APP == "https://api-pre.pujian.com") {
            window.location.href = "https://pay-pre.pujian.com/verify.html?isApp=0&env=pre&token=" + accountStorage.getCacheAccessToken() || ""
        } else {
            window.location.href = "https://pay-uat.pujian.com/verify.html?isApp=0&env=uat&token=" + accountStorage.getCacheAccessToken() || ""
        }
    } else if(code == 423){
      HttpClient.toast.instance.mount(HttpClient.toast.instance.create(data.msg),10000)
        setTimeout(()=>{
            this.cancel();
            this.#navigation.toLogin();
        },10000)
    } else if (code === 100001) {
      const messageMock = HttpClient.message.instance.get(code);
      const message = messageMock
        ? messageMock.statusText
        : data.msg || HttpClient.message.instance.get("0").statusText;
      HttpClient.toast.instance.mount(HttpClient.toast.instance.create(message),3000);
    } else if (code === 100002) {
      // 判断托运客户返回状态码
      if (data.msg === '9999' || data.msg === '10009') return response;
      const messageMock = HttpClient.message.instance.get(code);
      const message = messageMock
        ? messageMock.statusText
        : data.msg || HttpClient.message.instance.get("0").statusText;
      HttpClient.toast.instance.mount(HttpClient.toast.instance.create(message),3000);
    } else if (code == 500) {
      let message = '哎呀，系统出错了，请稍后再试~';
      HttpClient.toast.instance.mount(HttpClient.toast.instance.create(message),3000);
    } else if (code !== 200) {
      if (this.#options.useResponseToast) {
        const messageMock = HttpClient.message.instance.get(code);
        const message = messageMock
          ? messageMock.statusText
          : data.msg || HttpClient.message.instance.get("0").statusText;
          if (code != 8501) {
              HttpClient.toast.instance.mount(HttpClient.toast.instance.create(message),3000);
          }
      }
    }
    return response;
  };

  #createCancelTokenSource = () => {
    const source = this.#axios.CancelToken.source();
    const token = source.token;
    const cancel = source.cancel;
    return {
      token,
      cancel
    };
  };

  #registerDefaultInterceptor = () => {
    this.requestInterceptor.register(config => {
      return this.#defaultInterceptorRequestStart(config);
    });
    this.responseInterceptor.register(
      response => {
        return this.#defaultInterceptorResponseSuccess(response);
      },
      error => {
        return this.#defaultInterceptorResponseCatch(error);
      }
    );
  };

  #defaultInterceptorRequestStart = config => {

    if (this.#options.useSpinner && config.useSpinner!=false) {
      if (!HttpClient.spinner.instance.isMount()) {
        HttpClient.spinner.instance.mount(this.#options.spinnerDelay);
      }
    }
    if (this.#options.useDefaultParams) {
      const params = Object.assign({}, this.#requestOptions.params);
      if (
        config.params &&
        {}.toString.call(config.params) === "[object Object]"
      ) {
        config.params = Object.assign(params, config.params);
      } else {
        config.params = params;
      }
    }
    if (this.#options.useDefaultData) {
      const data = Object.assign({}, this.#requestOptions.data);
      if (config.data) {
        if ({}.toString.call(config.data) === "[object Object]") {
          config.data = Object.assign(data, config.data);
        } else if ({}.toString.call(config.data) === "[object String]") {
          config.data = qs.stringify(
            Object.assign(data, qs.parse(config.data))
          );
        }
      } else {
        config.data = data;
      }
    }
    return config;
  };

  #defaultInterceptorResponseCatch = error => {
    if (this.#axios.isCancel(error)) {
      error = error.message;
      const id = error.id;
      HttpClient.requests.has(id) && HttpClient.requests.delete(id);
      if (this.#options.useSpinner) {
        if (
          HttpClient.requests.size === 0 &&
          HttpClient.spinner.instance.isMount()
        ) {
          HttpClient.spinner.instance.unmount();
        }
      }
      if (this.#options.useRequestCancelToast) {
        const status = error.status;
        console.log('接口请求状态', status)
        const messageMock = HttpClient.message.instance.get(status);
        const message = messageMock
          ? messageMock.statusText
          : error.message || HttpClient.message.instance.get("0").statusText;
        HttpClient.toast.instance.mount(
          HttpClient.toast.instance.create(message)
        );
      }
    } else {
      const id = error.config.headers["Request-Id"];
      HttpClient.requests.has(id) && HttpClient.requests.delete(id);
      if (this.#options.useSpinner) {
        if (
          HttpClient.requests.size === 0 &&
          HttpClient.spinner.instance.isMount()
        ) {
          HttpClient.spinner.instance.unmount();
        }
      }
      if (this.#requestIsTimeout(error)) {
        if (this.#options.useRequestTimeoutToast) {
          const status = "601";
          console.log('接口请求状态', status)
          const messageMock = HttpClient.message.instance.get(status);
          const message = messageMock
            ? messageMock.statusText
            : error.message || HttpClient.message.instance.get("0").statusText;
          HttpClient.toast.instance.mount(
            HttpClient.toast.instance.create(message)
          );
        }
      } else {
        if (this.#options.useResponseCatchToast) {
          const status = error.response?.status;
          console.log('接口请求状态', status)
          const messageMock = HttpClient.message.instance.get(status);
          const message = messageMock
            ? messageMock.statusText
            : error.message || HttpClient.message.instance.get("0").statusText;
          HttpClient.toast.instance.mount(
            HttpClient.toast.instance.create(message)
          );
        }
      }
    }
    return Promise.reject(error);
  };

  #defaultInterceptorResponseSuccess = response => {
    const id = response.config.headers["Request-Id"];
    const timer = HttpClient.requests.get(id)[2];
    if (this.#options.useSpinner) {
      if (this.#options.duration > 0) {
        if (!timer.timeout) {
          return new Promise(resolve => {
            const createTimeoutHandler = () => {
              const timestamp = setTimeout(() => {
                clearTimeout(timestamp);
                if (timer.timeout) {
                  HttpClient.requests.has(id) && HttpClient.requests.delete(id);
                  if (
                    HttpClient.requests.size === 0 &&
                    HttpClient.spinner.instance.isMount()
                  ) {
                    HttpClient.spinner.instance.unmount();
                  }
                  return resolve(this.#responseProcessor(response));
                } else {
                  createTimeoutHandler();
                }
              }, 4);
            };
            createTimeoutHandler();
          });
        }
        return Promise.resolve(this.#responseProcessor(response));
      }
    }
    HttpClient.requests.has(id) && HttpClient.requests.delete(id);
    if (
      HttpClient.requests.size === 0 &&
      HttpClient.spinner.instance.isMount()
    ) {
      HttpClient.spinner.instance.unmount();
    }
    return Promise.resolve(this.#responseProcessor(response));
  };

  static requests = new Map();

  static options = {
    duration: 0,
    spinnerDelay: 200,
    useSpinner: true,
    useDefaultData: true,
    useDefaultParams: true,
    useResponseToast: true,
    useResponseCatchToast: true,
    useRequestCancelToast: false,
    useRequestTimeoutToast: true,
    useResponseRedirectLogin: true
  };

  static requestOptions = {
    data: {},
    params: {},
    timeout: 5000,
    withCredentials: true,
    headers: {}
  };

  static toast = {
    instance: new Toast()
  };

  static spinner = {
    instance: new Spinner()
  };

  static message = {
    instance: new Message()
  };

  constructor(options = {}, requestOptions = {}) {
    this.#options = Object.assign({}, HttpClient.options, options);
    if (requestOptions.headers) {
      requestOptions.headers = Object.assign(
        {},
        HttpClient.requestOptions.headers,
        requestOptions.headers
      );
    }
    this.#requestOptions = Object.assign(
      {},
      HttpClient.requestOptions,
      requestOptions
    );
    this.#axiosInstance = this.#axios.create(this.#requestOptions);
    this.#registerDefaultInterceptor();
  }

  original = this.#axios;

  originalInstance = this.#axiosInstance;

  requestInterceptor = (() => {
    const register = (...args) => {
      const interceptor = this.#axiosInstance.interceptors.request.use(...args);
      this.#interceptors.request.push(interceptor);
      return interceptor;
    };
    const cancel = id => {
      const index = this.#interceptors.request.indexOf(id);
      if (index > -1) {
        this.#interceptors.request.splice(index, 1);
      }
      this.#axiosInstance.interceptors.request.eject(id);
    };
    return {
      cancel,
      register
    };
  })();

  responseInterceptor = (() => {
    const register = (...args) => {
      const interceptor = this.#axiosInstance.interceptors.response.use(
        ...args
      );
      this.#interceptors.response.push(interceptor);
      return interceptor;
    };
    const cancel = id => {
      const index = this.#interceptors.response.indexOf(id);
      if (index > -1) {
        this.#interceptors.response.splice(index, 1);
      }
      this.#axiosInstance.interceptors.response.eject(id);
    };
    return {
      cancel,
      register
    };
  })();

  setOptions(options = {}) {
    this.#options = Object.assign(this.#options, options);
    return this;
  }

  setRequestOptions(requestOptions = {}) {
    this.#axiosInstance.defaults = this.#base.extend(
      this.#axiosInstance.defaults,
      requestOptions
    );
    return this;
  }

  cancel(request) {
    if (request) {
      HttpClient.requests.forEach((item, index) => {
        if (item[0] === request) {
          item[1]({
            id: index,
            status: 600,
            message: "请求已取消"
          });
        }
      });
    } else {
      HttpClient.requests.forEach((item, index) => {
        item[1]({
          id: index,
          status: 600,
          message: "请求已取消"
        });
      });
    }
  }

  get(url, config = {}) {
    return this.#createRequest({
      url,
      config,
      method: "get"
    });
  }

  put(url, data = {}, config = {}) {
    return this.#createRequest({
      url,
      data,
      config,
      method: "put"
    });
  }

  post(url, data = {}, config = {}) {
    return this.#createRequest({
      url,
      data,
      config,
      method: "post"
    });
  }

  head(url, config = {}) {
    return this.#createRequest({
      url,
      config,
      method: "head"
    });
  }

  patch(url, data = {}, config = {}) {
    return this.#createRequest({
      url,
      data,
      config,
      method: "patch"
    });
  }

  delete(url, config = {}) {
    return this.#createRequest({
      url,
      config,
      method: "delete"
    });
  }

  getUri(config = {}) {
    return this.#createRequest({
      config,
      method: "getUri"
    });
  }

  options(url, config = {}) {
    return this.#createRequest({
      url,
      config,
      method: "options"
    });
  }

  request(config = {}) {
    return this.#createRequest({
      config,
      method: "request"
    });
  }
}
