export default {
  name: "MyMessage",
  data: function data() {
    return {
      content: '',
      time: 3000,
      visible: false,
      type: 'info',
      //'success','warning','error'
      hasClose: false
    };
  },
  mounted: function mounted() {
    this.close();
  },
  methods: {
    getIconType: function getIconType() {
      if (this.type == 'success') {
        return 'icon-success-fill';
      }
      if (this.type == 'error') {
        return 'icon-reeor-fill';
      } else {
        return 'icon-prompt-fill';
      }
    },
    close: function close() {
      var _this = this;
      window.setTimeout(function () {
        _this.visible = false;
      }, this.time);
    }
  }
};