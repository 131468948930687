var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.visible ? _c('div', {
    staticClass: "confirm-wrap",
    on: {
      "cllck": function cllck($event) {
        _vm.closeOnClickModal && _vm.close();
      }
    }
  }, [_c('div', {
    staticClass: "confirm"
  }, [_vm.showClose ? _c('div', {
    staticClass: "confirm-close link-color",
    on: {
      "click": _vm.close
    }
  }, [_c('i', {
    staticClass: "iconfont icon-close"
  })]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.title,
      expression: "title"
    }],
    staticClass: "confirm-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "confirm-content"
  }, [_c('i', {
    staticClass: "iconfont icon-prompt-fill"
  }), _vm._v(" " + _vm._s(_vm.content) + " ")]), _c('div', {
    staticClass: "confirm-btn"
  }, [_vm.shwoCancelButton ? _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v(_vm._s(_vm.cancelButtonText) + " ")]) : _vm._e(), _vm.shwoConfirmButton ? _c('button', {
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        return _vm.close('confirm');
      }
    }
  }, [_vm._v(_vm._s(_vm.confirmButtonText) + " ")]) : _vm._e()])])]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };