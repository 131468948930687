import Vue from "vue";
import VueRouter from "vue-router";
import { indexRoute } from "./views/index.route.js";
import routerv2 from "./router-v2"
import { MessageBox } from 'element-ui';

Vue.use(VueRouter);

const routes = indexRoute;
routes.push(...routerv2);

const router = new VueRouter({
  mode: process.env.NODE_ENV === "production" ? "history" : "history",
  routes,
  //每次进入路由时，滚动条都在顶部
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  // console.log(to)
  // 路由是旧版搜索页面，则跳转到新版搜索页面
  if (
    to.name === 'search-detail' ||
    to.name === 'home' ||
    to.name === 'channel-detail' ||
    to.name === 'productList' ||
    to.name === 'details' ||
    to.name === 'order' ||
    to.name === 'cartList' ||
    to.name === 'activityztList'
  ) {
    let timeNum = 3, timer, message;

    if (to.name === 'search-detail') {
      message = `请前往新版商城进行商品搜索，${timeNum}秒后自动跳转，建议收藏新网址！`;
    } else {
      message = `请前往新版商城，${timeNum}秒后自动跳转，建议收藏新网址！`;
    }

    MessageBox.confirm(message, '提示', {
      confirmButtonText: '',
      cancelButtonText: '',
      showCancelButton: false,
      showConfirmButton: true,
      showClose: false,
      closeOnClickModal: false,
      closeOnPressEscape: false,
      type: 'warning'
    })
    timer = setInterval(() => {
      timeNum -= 1;
      if (timeNum == 0) {
        clearInterval(timer)
        timer = null;
        MessageBox.close()

        // 可以选择重定向到新版本路由
        if (to.name === 'search-detail') {
          next({
            name: "v2-search",
            params: { q: to.params.name }
          });
        } else if (to.name === 'home' || to.name === 'activityztList') {
          next({
            name: "v2-home"
          });
        } else if (to.name === 'channel-detail' || to.name === 'productList') {
          next({
            name: "v2-product",
            params: { id: '20' },
            query: {
              catalogName: '支气管炎',
              id: 127,
              type: 3
            }
          });
        } else if (to.name === 'details') {
          next({
            name: "v2-detail",
            params: {
              id: to.params.id,
              sale: to.params.sale
            }
          });
        } else if (to.name === 'order') {
          next({
            name: "v2-order",
            params: {
              status: "0"
            }
          });
        } else if (to.name === 'cartList') {
          next({
            name: "v2-shopcard"
          });
        }
      }
    }, 1000)
  } else {
    // 继续导航
    next();
  }
});

// 防止重复点击这个路由报错
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

export default router;
