import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var debtMessageRoute = [{
  path: "debt-message",
  name: "debtMessage",
  component: function component() {
    return import( /* webpackChunkName: "login" */"./debt-message.vue");
  },
  meta: {
    keepAlive: true
  }
}, {
  path: "debt-message-detail/:id",
  name: "debtMessageDetail",
  component: function component() {
    return import( /* webpackChunkName: "login" */"./debt-message-detail.vue");
  }
}];