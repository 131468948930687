import "core-js/modules/es.array.concat.js";
import { accountRoute } from "./account/account.route.js";
import { testRoute } from "./test-component/test.route";
import { stateRoute } from "./state/state.route.js";
import { publicRoute } from "./public/public.route.js";
import { homeRoute } from "./home/home.route.js";
import { productRoute } from "./product/product.route.js";
import { userCenterRoute } from "./user-center/user-center.route.js";
import { channelRoute } from "./channel/channel.route.js";
import { searchRoute } from "./search/search.route.js";
import { cartRoute } from "./cart/cart.route.js";
import { messageCenterRoute } from "./message-center/message-center.route";
export var indexRoute = homeRoute.concat(testRoute).concat(accountRoute).concat(productRoute).concat(userCenterRoute).concat(channelRoute).concat(searchRoute).concat(cartRoute).concat(stateRoute).concat(publicRoute).concat(messageCenterRoute);