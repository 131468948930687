import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { channelDetailRoute } from "./channel-detail/channel-detail.route.js";
// import { detailsRoute } from "./product-details/details.route.js";

export var channelRoute = [{
  path: "/channel",
  name: "channel",
  component: function component() {
    return import("./channel.vue");
  },
  children: channelDetailRoute
}];