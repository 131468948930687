//默认购买数量处理
export function defaultBuyNum(datas) {
  datas.forEach(v => {
    if (v.activityVOList && v.activityVOList.length) {
      // type 为3时，说明是特价品种
      let activityIndex = v.activityVOList.findIndex(item => item.type == 3);
      // 过虑出特价品种数据
      let activityGoodArr = v.activityVOList.filter(item => item.type == 3);
      // 判断是否是特价品种
      v.isActivityGood = activityIndex !== -1;
      // 添加特价品种数据
      v.activityGoodInfo = activityGoodArr[0] || {};
    } else {
      v.isActivityGood = false;
      v.activityGoodInfo = {}
    }
  })
  return datas
}

// 默认购买数量处理
export function purchaseQuantityProcessing(datas) {
  if (datas.length == 0) return [];

  datas.forEach(v => {
    if (!v.activityVo) {
      v.activityVo = {
        hasActivity: false,
        activityStrategyReduceVOS: []
      }
    }
    v.isMediumPackage = v.isMiddlePack;
    v.mediumPackage = v.packageNumber;
    v.availableNum = v.availableStock;

    if (v.unitPrice) {
      v.unitPrice = String(v.unitPrice.toFixed(2))
    }

    // bigPreferentialType  1.票折  2.特价  3.赠品  4.票折+红包  5.票折+特价  6.票折+赠品  7.特价+赠品

    // buyCount 推荐购买数量（取活动满足数量）  判断逻辑，满足条件类型编码(1->满量,2-> 满额)  condTypeCode = 1的，然后取满足度量condMeas
    let { hasActivity, planListVOS, activityStrategyReduceVOS } = v.activityVo;
    // 有活动
    if (hasActivity) {
      activityStrategyReduceVOS.forEach(item => {
        if (item.activityPrice) item.activityPrice = item.activityPrice.toFixed(2).toString()
      })

      if (activityStrategyReduceVOS && activityStrategyReduceVOS.length) {
        // preferentialType 让利类型  (1.票折，2.红包，3.特价, 4.赠品)
        let activityIndex = activityStrategyReduceVOS.findIndex(item => item.preferentialType == 3);
        let redAnvelopeActivityIndex = activityStrategyReduceVOS.findIndex(item => item.preferentialType == 2);
        // 判断是否是红包活动
        v.isRedAnvelopeActivity = redAnvelopeActivityIndex !== -1;

        // 过虑出特价品种数据
        let activityGoodArr = activityStrategyReduceVOS.filter(item => item.preferentialType == 3);
        // 判断是否是特价品种
        v.isActivityGood = activityIndex !== -1;
        // 添加特价品种数据
        v.activityGoodInfo = activityGoodArr[0] || {};
      } else {
        v.isActivityGood = false;
        v.activityGoodInfo = {}
      }

      // 有活动商品
      if (activityStrategyReduceVOS && activityStrategyReduceVOS.length > 0) {
        // 中包装
        if (v.isMiddlePack == 1) {
          v.buyCount = v.meetWithActiveNum;
          v.activityGoodInfo.activityCount = v.meetWithActiveNum;
        } else {
          v.buyCount = activityStrategyReduceVOS[0].condMeas;
          v.activityGoodInfo.activityCount = activityStrategyReduceVOS[0].condMeas;
        }
      }
    } else {
      // 没有活动时取最低限制数量 notLowerSale
      v.buyCount = v.notLowerSale || 1
    }
  })
  return datas
}

// 数组对象去重
export function arrUnique(arr, key) {
  var obj = {};
  const arrays = arr.reduce((setArr, item) => {
    obj[item[key]] ? '' : obj[item[key]] = true && setArr.push(item);
    return setArr;
  }, []);
  return arrays;
}

//【获取n天前时间】: 传参数几就是今天往前推几天的日期。比如传7就是往前七天的日期
export function theOtherDay(n) {
  //开始时间
  var nowDate = new Date();
  let befDate = new Date(nowDate.getTime() - n * 24 * 3600 * 1000);
  let byear = befDate.getFullYear();
  let bmonth = befDate.getMonth() + 1;
  if (bmonth >= 1 && bmonth <= 9) {
    bmonth = "0" + bmonth;
  }
  let bday = befDate.getDate() - 1;
  if (bday >= 0 && bday <= 9) {
    bday = "0" + bday;
  }

  //截止时间
  let endDate = new Date(nowDate.getTime() - 1 * 24 * 3600 * 1000);
  let year = endDate.getFullYear();
  let month = endDate.getMonth() + 1;
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  let day = endDate.getDate() + 1;
  if (day >= 0 && day <= 9) {
    day = "0" + day;
  }

  //赋值
  let timerValue = [
    byear + "-" + bmonth + "-" + bday,
    year + "-" + month + "-" + day,
  ];

  return timerValue;
}

// url下载文件
export function urlDownloadFile(url, name) {
  fetch(url).then(res => res.blob()).then(blob => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = name; // 下载文件的名字
    document.body.appendChild(a);
    a.click()
  })
}
