import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { searchDetailRoute } from "./search-detail/search-detail.route.js";
// import { detailsRoute } from "./product-details/details.route.js";

export var searchRoute = [{
  path: "/search",
  name: "search",
  component: function component() {
    return import("./search.vue");
  },
  children: searchDetailRoute
}];