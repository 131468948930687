let v='/v2'
const routeV2=[
    {
        path:v+"/login",
        name:"v2-login",
        meta:{title:'登录页面'},
        component:() => import("@/views-v2/login/index.vue")
    },
    {
        path:'/',
        name:"v2-home",
        meta:{title:'首页'},
        component:() => import("@/views-v2/home/index")
    },
    {
      path:v+'/activity/:id',
      name:"v2-activity",
      meta:{title:'活动'},
      component:() => import("@/views-v2/activity/index")
    },
    {
      path:v+'/video',
      name:"v2-video",
      meta:{title:'视频红包'},
      component:() => import("@/views-v2/video-redbag/index")
    },
    {
        path:v+"/list/:id",
        name:"v2-product",
        meta:{title:'产品列表'},
        component:() => import("@/views-v2/zq-common/index")
    },
    {
        path:v+"/planlist",
        name:"v2-plan",
        meta:{title:'我的计划'},
        component:() => import("@/views-v2/my-plan/index")
    },
    {
        path:v+"/product/details/:id/:sale",
        name:"v2-detail",
        meta:{title:'商品详情'},
        component:() => import("@/views-v2/product/detail/product-details")
    },
    {
        path:v+"/shopcard",
        name:"v2-shopcard",
        meta:{title:'购物车'},
        component:() => import("@/views-v2/shopping-card")
    },
    {
        path:v+"/payresult",
        name:"v2-payresult",
        meta:{title:'支付结果'},
        component:() => import("@/views-v2/pay-result/pay-result.vue")
    },
    {
        path:v+"/payment",
        name:"v2-payment",
        meta:{title:'线上支付'},
        component:() => import("@/views-v2/payment/index.vue")
    },
    {
        path:v+"/orderconfirm/:id",
        name:"v2-orderconfirm",
        meta:{title:'订单确认'},
        component:() => import("@/views-v2/order-confirm/index")
    },
    {
        path:v+"/search/:q",
        name:"v2-search",
        meta:{title:'产品搜索'},
        component:() => import("@/views-v2/search/index")
    },
    {
      path:v+"/hotProduct",
      name:"v2-hotProduct",
      meta:{title:'爆款'},
      component:() => import("@/views-v2/hot-product/index")
  },{
        path:v+"/controlSell",
        name:"v2-controlSell",
        meta:{title:'控销'},
        component:() => import("@/views-v2/control-sell/index")
    },
    {
        path:v+"/details",
        name:"v2-details",
        redirect:v+'/details/information',
        meta:{title:'个人中心'},
        component:() => import("@/views-v2/user-center/index"),
        children: [
            {
              path: 'information',
              name:'v2-information',
              component: ()=>import('@/views-v2/user-center/elementary/information.vue'),
              meta: { title: '基本信息' }
            },
            {
                path: 'myaccount',
                name:'v2-myaccount',
                component: ()=>import('@/views-v2/user-center/bill/bill.vue'),
                meta: { title: '我的账户' }
              },
              {
                path: 'security',
                name:'v2-security',
                component: ()=>import('@/views-v2/user-center/accountsecurity/accountsecurity.vue'),
                meta: { title: '账号安全' }
              },
              {
                path: 'certification',
                name:'v2-certification',
                component: ()=>import('@/views-v2/user-center/certification/certification.vue'),
                meta: { title: '我的资质' }
              },
              {
                path: 'audit',
                name:'v2-audit',
                component: ()=>import('@/views-v2/user-center/audit/audit.vue'),
                meta: { title: '我的审核' }
              },
              {
                path: 'order/:status',
                name:'v2-order',
                component: ()=>import('@/views-v2/user-center/order/order.vue'),
                meta: { title: '我的订单' }
              },
              {
                path: 'reconciliation',
                name:'v2-reconciliation',
                component: ()=>import('@/views-v2/user-center/reconciliation/index.vue'),
                meta: { title: '我的账单' }
              },
              {
                path: 'planbook',
                name:'v2-planlist',
                component: ()=>import('@/views-v2/user-center/plan/plan.vue'),
                meta: { title: '报计划' }
              },
              {
                path: 'planbook-create/:id',
                name:'v2-planlist-create',
                component: ()=>import('@/views-v2/user-center/plan/create.vue'),
                meta: { title: '报计划' }
              },
              {
                path: 'collection',
                name:'v2-collection',
                component: ()=>import('@/views-v2/user-center/collection/collection.vue'),
                meta: { title: '我的收藏' }
              },
              {
                path: 'invoicetop',
                name:'v2-invoicetop',
                component: ()=>import('@/views-v2/user-center/invoicetop/invoicetop.vue'),
                meta: { title: '发票抬头' }
              },
              {
                path: 'electronicInvoiceList',
                name:'v2-electronicInvoiceList',
                component: ()=>import('@/views-v2/user-center/electronicInvoiceList/electronicInvoiceList.vue'),
                meta: { title: '发票中心' }
              },
              {
                path: 'download',
                name:'v2-download',
                component: ()=>import('@/views-v2/user-center/data-download/data-download.vue'),
                meta: { title: '药品资料下载' }
              },
              {
                path: 'drugtest',
                name:'v2-drugtest',
                component: ()=>import('@/views-v2/user-center/data-download/drug-download.vue'),
                meta: { title: '药品检验报告下载' }
              },
              {
                path: 'complaint',
                name:'v2-complaint',
                component: ()=>import('@/views-v2/user-center/complaint/complaint.vue'),
                meta: { title: '投诉建议' }
              },
              {
                path: 'history',
                name:'v2-history',
                component: ()=>import('@/views-v2/user-center/history/history.vue'),
                meta: { title: '历史记录' }
              },
              {
                path: 'coupon',
                name:'v2-coupon',
                component: ()=>import('@/views-v2/user-center/coupon/coupon.vue'),
                meta: { title: '全部优惠卷' }
              },
              {
                path: 'news',
                name:'v2-news',
                component: ()=>import('@/views-v2/user-center/news/news.vue'),
                meta: { title: '消息中心' }
              },
              {
                path: 'address',
                name:'v2-address',
                component: ()=>import('@/views-v2/user-center/address/index.vue'),
                meta: { title: '收货地址' }
              },
              {
                path: 'buy',
                name:'v2-buy',
                component: ()=>import('@/views-v2/user-center/uploadImg/uploadImg.vue'),
                meta: { title: '商品建议' }
              },
              {
                path: "/shoppingDetails",
                name: "v2-shoppingDetails",
                meta: {title: '开货明细'},
                component: () => import("@/views-v2/shopping-details/index")
              },
          ]
    },
     // 订单详情
     {
      path: v+'/orderpage/:orderNumber',
      name:'v2-orderpage',
      component: ()=>import('@/views-v2/user-center/order-detail/order-detail'),
      meta: { title: '查看详情' }
    }
];
export default routeV2;
