import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var stateRoute = [{
  path: "/404",
  name: "404",
  alias: "*",
  component: function component() {
    return import("./404.vue");
  }
}];