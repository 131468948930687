import "core-js/modules/es.object.to-string.js";
export default {
  name: 'Confirm',
  data: function data() {
    return {
      visible: false,
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      shwoCancelButton: true,
      content: '',
      title: '',
      showClose: true,
      shwoConfirmButton: true,
      closeOnClickModal: false
    };
  },
  methods: {
    confirm: function confirm() {
      var _this = this;
      this.visible = true;
      return new Promise(function (resolve, reject) {
        _this.promiseStatus = {
          resolve: resolve,
          reject: reject
        };
      });
    },
    close: function close(val) {
      this.visible = false;
      if (val == 'confirm') {
        this.promiseStatus && this.promiseStatus.resolve();
      } else {
        this.promiseStatus && this.promiseStatus.reject();
      }
    }
  }
};