import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { planBookDetailRoute } from "./details/plan-book-detail.route";
import { planBookListRoute } from "./list/plan-book-list.route";
export var planBookRoute = [{
  path: "planBook",
  name: "planBook",
  redirect: 'planBook/planBookList',
  component: function component() {
    return import("./plan-book.vue");
  },
  children: planBookListRoute.concat(planBookDetailRoute)
}];