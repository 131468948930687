import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { cartListRoute } from "./cart-list/cart-list.route.js";
import { orderConfirmRoute } from "./order-confirm/order-confirm.route.js";
import { payMentRoute } from "./pay/pay-ment.route.js";
import { payResultRoute } from "./pay-result/pay-result.route.js";
export var cartRoute = [{
  path: "/cart",
  name: "cart",
  component: function component() {
    return import("./cart.vue");
  },
  children: cartListRoute.concat(orderConfirmRoute).concat(payMentRoute).concat(payResultRoute)
}];