import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
export var publicRoute = [{
  path: "/notice",
  name: "notice",
  alias: "*",
  component: function component() {
    return import("./index.vue");
  }
}];