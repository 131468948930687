var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.visible ? _c('div', {
    staticClass: "message",
    class: _vm.type
  }, [_c('div', {
    staticClass: "content"
  }, [_c('i', {
    staticClass: "icon-type iconfont",
    class: _vm.getIconType()
  }), _vm._v(" " + _vm._s(_vm.content) + " ")]), _vm.hasClose ? _c('i', {
    staticClass: "btn-close iconfont icon-close",
    on: {
      "click": function click($event) {
        _vm.visible = false;
      }
    }
  }) : _vm._e()]) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };